import React from 'react'

const PostDetails = props => {
  return (
<>
<div className="level-item"><p>{props.date} //</p> </div>
<div className="level-item"><p>{`${props.timeToRead} min read `}</p></div>
</>
      
  )
}

export default PostDetails
