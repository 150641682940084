import React from 'react'

const Hero = props => (
  <section className="hero is-medium"
  >
    <div className="hero-body">
      <div className="container has-text-centered">
      <div className="content">
      <h1 className="title">{props.title}</h1>

      </div>
      </div>
    </div>
  </section>
)

export default Hero
