import React from 'react'
import { Link } from 'gatsby'

const TagList = props => {
  return (
    <>
      {props.tags.map(tag => (
        <span className="tag is-light is-primary level-item" key={tag.id}>
          <Link to={`/tag/${tag.slug}/`}>{tag.title}</Link>
        </span>
      ))}
    </>
  )
}

export default TagList
