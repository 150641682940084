import React from 'react'

const PageBody = props => {
  return (
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: props.body.childMarkdownRemark.html,
        }}
      />
  )
}

export default PageBody
