import React from 'react'
import { Link } from 'gatsby'
import { Box, Button} from '@theme-ui/components'

const PostLinks = props => {
  return (
      <Box>
        {props.previous && (
          <Link to={`/blog/${props.previous.slug}/`}>
          <Button>
          &#8592; Prev Post
          </Button>
            
          </Link>
        )}
        {props.next && (
          <Link to={`/blog/${props.next.slug}/`}>
          <Button>
          Next Post &#8594;
          </Button></Link>
        )}
      </Box>
  )
}

export default PostLinks
